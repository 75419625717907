<template>
  <div class="bg">
    <div class="container">
      <div class="banner">
        <span>当前位置：众惠首页 &gt;</span>
        <span>基金学堂 &gt;</span>
        <span style="color: #6b6b6b">{{ $route.query.mode }}</span>
      </div>
      <div class="middle">
        <h3>{{ $route.query.mode }}</h3>
        <div class="content">
          <div v-for="(item, index) in mydata" :key="index" class="box">
            <div class="box-content">
              <div class="box-left">
                <router-link :to="'detail?infoId='+item.infoId">
                  <img :src="item.webCoverUrl" alt />
                </router-link>
                <div>
                  <router-link :to="'detail?infoId='+item.infoId" class="box-p">{{ item.title }}</router-link>
                  <div class="box-h2">{{ item.infoSource }}</div>
                  <div class="box-h4">来源</div>
                </div>
              </div>
              <div class="box-right">
                <div class="hot">
                  <span class="iconfont">&#xe680;</span>
                  <span>{{ item.origReadCount }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style  scoped>
.bg {
  background-color: #f3f4f6;
  margin-bottom: -30px;
}
.container {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding-bottom: 40px;
}
.banner {
  height: 16px;
  width: 248px;
  text-align: left;
  color: #a5a5a5;
  font-size: 14px;
  padding-top: 24px;
  padding-bottom: 24px;
  line-height: 16px;
}
.middle {
  height: 100%;
  background-color: #fff;
  margin: 0 auto;
  padding: 40px;
}
.middle h3 {
  margin: 24px 0;
  font-size: 20px;
  font-weight: 700;
}
.box {
  height: 156px;
  width: 100%;
  margin-right: 40px;
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 1px 0px 0px #edf1f7;
}
.box-content {
  height: 116px;
  margin: 20px;
  display: flex;
  justify-content: space-between;
}
.box-left {
  display: flex;
}
.box-left img {
  width: 174px;
  height: 116px;
  border-radius: 7px;
  margin-right: 24px;
}
.box-p {
  margin-top: 14px;
  font-size: 14px;
  font-weight: 300;
  color: #313237;
  display: block;
}
.box-h2 {
  font-size: 9px;
  font-weight: 400;
  color: #313237;
  margin-top: 49px;
  display: block;
}
.box-h4 {
  color: #8691a8;
  font-size: 9px;
  font-weight: 400;
  display: block;
}
.box-right {
  line-height: 220px;
  margin-right: 15px;
  margin-bottom: 21px;
}
.hot {
  display: flex;
  align-items: center;
}
.box-right span {
  font-size: 9px;
  color: #8691a8;
  font-weight: 400;
}
</style>

<script>
import { fundSchool } from '@/api/activeList'
export default {
  data() {
    return {
      mydata: []
    }
  },
  mounted() {
    this.school()
  },
  methods: {
    school() {
      fundSchool({
        // 资讯类型 0:热点资讯 1:基金学堂
        infoType: '1',
        // 学堂类型 0:基金学堂模块A 1:基金学堂模块B 2:基金学堂模块C
        schoolType: this.$route.query.schoolType,
        publishStatus: '1'
      }).then((res) => {
        if (res.code === 200) {
          console.log(res.data.data)
          this.mydata = res.data.data
        }
      })
    }
  }
}
</script>
